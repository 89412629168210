import React from 'react';
import './Divider.css';


export const Divider: React.FC = () => {
    return (
      <div className="container">
        <div className="border" />
      </div>
    );
  };