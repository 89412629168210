import React from 'react';
import { Backplate } from '../Backplate/Backplate';
import { Button, Gapped, Input, Toast } from '@skbkontur/react-ui';
import { useCategories } from '../../hooks/categories';
import { CategoryClient } from '../../api/category';

interface Props {
    isMobile?: boolean;
}

export const AddCategoryForm: React.FC<Props> = ({isMobile}) => {
    const { addCategory } = useCategories();
    const [category, setCategory] = React.useState('');

    function handleAddCategory(categoryName: string) {
        if (categoryName) {
            CategoryClient.addCategory(categoryName).then(newCategory => {
                addCategory(newCategory);
                setCategory('');
            }).catch(() => {
                Toast.push('Не удалось добавить категорию');
            });
        }
        else {
            Toast.push("Укажите имя категории для добавления")
        }
    }

    if (isMobile) {
        return (
            <Backplate title='Добавить категорию' isMobile={isMobile}>
                <Gapped gap={30} vertical>
                    <Input value={category} onValueChange={(v) => setCategory(_ => v)} />
                    <Button onClick={() => handleAddCategory(category) }>Добавить</Button>
                </Gapped>
            </Backplate>
        );
    }

    return (
        <Backplate title='Добавить категорию'>
            <Gapped gap={30}>
                <div>
                    <div>Категория</div>
                    <Input value={category} onValueChange={(v) => setCategory(_ => v)} />
                </div>
                <Button onClick={() => handleAddCategory(category) }>Добавить</Button>
            </Gapped>
        </Backplate>
    );
}