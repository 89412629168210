import React from 'react';
import { CurrencyInput, ComboBox, ComboBoxItem, Button, Gapped, Toast } from "@skbkontur/react-ui";
import { Nullable } from '@skbkontur/react-ui/cjs/typings/utility-types';
import { Backplate } from '../Backplate/Backplate';
import './AddPayForm.css';
import { useCategories } from '../../hooks/categories';
import { PaymentClient } from '../../api/payment'

interface Props {
    isMobile?: boolean;
}

export const AddPayForm: React.FC<Props> = ({isMobile}) => {
    const [value, setValue] = React.useState<Nullable<number>>(null);
    const [category, setCategory] = React.useState<Nullable<ComboBoxItem>>(null);
    const { categories } = useCategories();

    function getItems(filterItem: string) {
        const items = categories.map((item) => ({value: item.id, label: item.name}))
            .filter(x => !filterItem || filterItem.length === 0 || x.label.toLowerCase().includes(filterItem.toLowerCase()));
        return Promise.resolve(items);
    }

    function addPayment() {
        if (value && category) {
            PaymentClient.addPayment(value, category.value).then(_ => {
                Toast.push("Оплата добавлена")
            }).catch(_ => {
                Toast.push("Не удалось добавить оплату")
            })
        }
        else {
            Toast.push("Укажите сумму и категорию, чтобы добавить оплату")
        }
    }

    if (isMobile) {
        return (
            <Backplate title='Добавить оплату' isMobile={isMobile}>
                <Gapped gap={30} vertical>
                    <div>
                        <div>Сумма</div>
                        <div>
                            <CurrencyInput value={value} onValueChange={(v) => setValue(_ => v)} />  руб.
                        </div>
                    </div>
                    <div>
                        <div>Категория</div>
                        <div>
                            <ComboBox
                                getItems={getItems}
                                value={category}
                                onValueChange={(v) => setCategory(_ => v)}

                            />
                        </div>
                    </div>
                    <Button onClick={addPayment}>Добавить</Button>
                </Gapped>
            </Backplate>
        );
    }

    return (
        <Backplate title='Добавить оплату'>
            <Gapped gap={30}>
                <div>
                    <div>Сумма</div>
                    <div>
                        <CurrencyInput value={value} onValueChange={(v) => setValue(_ => v)} />  руб.
                    </div>
                </div>
                <div>
                    <div>Категория</div>
                    <div>
                        <ComboBox
                            getItems={getItems}
                            value={category}
                            onValueChange={(v) => setCategory(_ => v)}
                        />
                    </div>
                </div>
                <Button onClick={addPayment}>Добавить</Button>
            </Gapped>
        </Backplate>
    );
}