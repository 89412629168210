import React from 'react';
import { Gapped, THEME_2022, ThemeContext} from "@skbkontur/react-ui";
import { MoneyCurrencyRubleCircleIcon16Solid } from "@skbkontur/icons";
import './App.css';
import { isMobile } from './tools/devices';
import { AddPayForm } from "./components/AddPayForm/AddPayForm"
import { AddCategoryForm } from './components/AddCategoryForm/AddCategoryForm';
import { Divider } from './common/Divider/Divider';
import { CategoryContext } from './hooks/categories';
import { AddIncomeForm } from './components/AddIncomeForm/AddIncomeForm';



const DesktopApp: React.FC = () => {
  return (
    <div className="app-desktop">
      <header className="app-header">
        <div>
          <MoneyCurrencyRubleCircleIcon16Solid className="app-logo" size={32} /> Учет расходов
        </div>
      </header>
      <Gapped gap={20} vertical>
          <AddPayForm />
          <AddIncomeForm />
          <AddCategoryForm />
      </Gapped>
    </div>
  );
}

const MobileApp: React.FC = () => {
  return (
    <div className="app-mobile">
      <header className="app-header-mobile">
        <div>
          <MoneyCurrencyRubleCircleIcon16Solid className="app-logo" size={32} /> Учет расходов
        </div>
      </header>
      <Gapped gap={5} vertical>
          <Divider />
          <AddPayForm isMobile />
          <Divider />
          <AddIncomeForm isMobile />
          <Divider />
          <AddCategoryForm isMobile />
      </Gapped>
    </div>
  );
}

function App() {
  const app = isMobile() ? <MobileApp /> : <DesktopApp />;

  return (
    <div className="app-container">
      <ThemeContext.Provider value={THEME_2022}>
        <CategoryContext>
          {app}
        </CategoryContext>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
