import { useState, createContext, useContext, useEffect } from "react";
import { Category } from "../models/categories";
import { CategoryClient } from "../api/category";

interface Props {
    children: React.ReactNode;
}

interface CategoryState {
    categories: Category[];
    setCategories: (categories: Category[]) => void;
}

const CategoryContextState = createContext<CategoryState>({ categories: [], setCategories: () => {} });

export const CategoryContext: React.FC<Props> = ({children}) => {
    return (
        <CategoryContextState.Provider value={BuildContext()}>
            {children}
        </CategoryContextState.Provider>
    );
}

function BuildContext(): CategoryState {
    const [categories, setCategories] = useState<Category[]>([]);

    useEffect(() => {
        CategoryClient.getCategories().then(setCategories).catch((err) => {
            console.error("Failed to load categories", err);
        });
    }, [])

    return { categories, setCategories };
}

export const useCategories = () => {
    const { categories, setCategories } = useContext(CategoryContextState);

    function addCategory(category: Category) {
        setCategories([...categories, category]);
    }

    return {categories, setCategories, addCategory};
};