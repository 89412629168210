import { Category } from '../models/categories';
import { api } from './api';
import { ApiResponse, retrieveData } from './tools';

export class CategoryClient {
    public static addCategory(category: string): Promise<Category> {
        return api.post<ApiResponse<PostCategoryResponse>>('/categories', {name: category})
            .then(retrieveData)
            .then(data => data.category)
    }

    public static getCategories(): Promise<Category[]> {
        return api.get<ApiResponse<GetCategoriesResponse>>('/categories')
            .then(retrieveData)
            .then((data) => data.categories)
    }
}

interface PostCategoryResponse {
    category: Category
}

interface GetCategoriesResponse {
    categories: Category[]
}
