import { AxiosResponse } from "axios";

export interface ApiResponse<T = any> {
    data: T
    errors: string[]
}

export function retrieveData<T = any>(response: AxiosResponse<ApiResponse<T>, any>): Promise<T> {
    if (response.status >= 300) {
        throw new Error(response.statusText);
    }

    const data = response.data;

    if (data.errors &&data.errors.length > 0) {
        throw new Error(data.errors.join('\n'));
    }

    return Promise.resolve(response.data.data);
}

export function checkResponseStatus<T = any>(response: AxiosResponse<T, any>): Promise<void> {
    if (response.status >= 300) {
        throw new Error(response.statusText);
    }

    return Promise.resolve();
}