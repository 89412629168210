import React from 'react';
import { Nullable } from '@skbkontur/react-ui/cjs/typings/utility-types';
import { Button, CurrencyInput, Gapped, Toast } from '@skbkontur/react-ui';
import { Backplate } from '../Backplate/Backplate';
import { IncomeClient } from '../../api/income';

interface Props {
    isMobile?: boolean
}

export const AddIncomeForm: React.FC<Props> = ({isMobile}) => {
    const [value, setValue] = React.useState<Nullable<number>>(null);

    function addIncome() {
        if (value) {
            IncomeClient.addIncome(value).then(_ => {
                Toast.push("Пополнение добавлено")
            }).catch(_ => {
                Toast.push("Не удалось добавить пополнение")
            })
        }
        else {
            Toast.push("Укажите сумму, чтобы добавить пополнение")
        }
    }

    if (isMobile) {
        return (
            <Backplate title='Добавить пополнение' isMobile={isMobile}>
                <Gapped gap={30} vertical>
                    <div>
                        <div>Сумма</div>
                        <div>
                            <CurrencyInput value={value} onValueChange={(v) => setValue(_ => v)} />  руб.
                        </div>
                    </div>
                    <Button onClick={addIncome}>Добавить</Button>
                </Gapped>
            </Backplate>
        );
    }

    return (
        <Backplate title='Добавить пополнение'>
            <Gapped gap={30}>
                <div>
                    <div>Сумма</div>
                    <div>
                        <CurrencyInput value={value} onValueChange={(v) => setValue(_ => v)} />  руб.
                    </div>
                </div>
                <Button onClick={addIncome}>Добавить</Button>
            </Gapped>
        </Backplate>
    );
}