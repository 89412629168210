import React from 'react';
import './Backplate.css';
import cn from 'classnames';

interface Props {
    children: React.ReactNode;
    title?: string;
    className?: string;
    isMobile?: boolean;
}

export const Backplate: React.FC<Props> = ({children, title, className, isMobile}) => {
    if (isMobile) {
        return (
            <div className={cn("backplate-mobile", className)}>
                {title && <div className="title">{title}</div>}
                {children}
            </div>
        );
    }

    return (
        <div className={cn("backplate", className)}>
            {title && <div className="title">{title}</div>}
            {children}
        </div>
    );
}